<template>
  <el-dialog :visible="visible" title="重置密码" width="400px" @close="close">
    <div class="reset-form-item">
      <span>账号名称:</span><span>{{ form.nickname }}</span>
    </div>
    <div class="reset-form-item">
      <span>手机号码:</span><span>{{ form.username }}</span>
    </div>
    <div class="reset-form-item">
      <span>重置密码链接:</span><span id="copyText">{{ copyText }}</span>
    </div>
    <div class="reset-form-item">
      <span>邮箱地址:</span>
      <span v-if="form.email">{{ form.email }}</span>
      <span v-else style="color: #1890FF; cursor: pointer" @click="onAddEmail">添加</span>
    </div>
    <div v-show="showTips" style="color: red">
      请填写邮箱地址
    </div>
    <template #footer>
      <el-button size="small" @click="onCopy">
        复制链接
      </el-button>
      <el-button size="small" type="primary" @click="sendEmail">
        发送至邮箱
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {getResetPageUrl, sendEmail} from "@/api/account";

export default {
  name: 'UserResetPassword',
  data () {
    return {
      visible: false,
      form: {
        nickname: '',
        username: '',
        email: ''
      },
      copyText: '',
      showTips: false,
      pathCode: '',
    };
  },
  methods: {
    async sendEmail () {
      this.showTips = !this.form.email;
      if (!this.form.email) return;
      const res = await sendEmail(this.pathCode);
      this.$message.success('已发送 ');
      this.close();
    },
    onAddEmail () {
      this.visible = false;
      this.$emit('edit-account', [this.form]);
    },
    async open (form) {
      this.form = form;
      const res = await getResetPageUrl(this.form.id);
      this.copyText = res.url;
      this.pathCode = res.path;
      this.visible = true;
    },
    close () {
      this.visible = false;
      this.showTips = false;
    },
    onCopy () {
      const sel = window.getSelection();
      const range = document.createRange();
      range.selectNode(document.getElementById('copyText'));
      sel.removeAllRanges();
      sel.addRange(range);
      const state = document.execCommand('copy');
      if (state) {
        this.$message.success('复制成功');
        this.visible = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.reset-form-item {
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
  :last-child {
    color: #666666;
    margin-left: 8px;
  }
}
</style>
