<template>
  <el-drawer :visible="visible" size="50%" custom-class="user-operation-drawer" @close="close">
    <template #title>
      <h3>{{ account ? '编辑账号' : '创建账号' }}</h3>
    </template>
    <div v-loading="loading" class="user-operation">
      <el-form ref="form" :rules="formRules" :model="formModel" label-position="left" label-width="80px">
        <el-form-item label="选择角色" prop="roleIds">
          <el-select v-model="formModel.roleIds" multiple size="small" style="width: 100%" @change="handlerSelectRole">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
              {{ item.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="nickname" label="账号姓名">
          <el-input v-model="formModel.nickname" placeholder="请输入账号姓名" size="small" />
        </el-form-item>
        <el-form-item prop="username" label="手机号">
          <el-input v-model="formModel.username" placeholder="请输入手机号" size="small" maxlength="11" />
        </el-form-item>
        <el-form-item prop="departmentIds" label="部门">
          <el-cascader
            v-model="formModel.departmentIds"
            :props="{
              label: 'name',
              value: 'id',
              checkStrictly: true,
              multiple: true,
            }"
            :options="departmentList"
            size="small"
            style="width: 100%"
            placeholder="请选择部门"
          />
        </el-form-item>
        <el-form-item prop="email" label="邮箱地址">
          <el-input v-model="formModel.email" placeholder="请输入邮箱地址" size="small" />
        </el-form-item>
      </el-form>
      <div>
        <el-checkbox v-if="!account" v-model="formModel.isSendEmail" :true-label="1" :false-label="0">
          <span style="color: #606266; font-weight: 400">发送账号及密码至邮箱</span>
        </el-checkbox><br>
        <span style="display: inline-block; width: 80px; color: #606266;">是否启用</span>
        <el-switch v-model="formModel.status" :active-value="3" :inactive-value="1" />
        <div v-if="false" class="rule-setting">
          <p><span>权限设置</span></p>
          <PermissionSettingApp ref="permissionSetting" :menu-list="permissionList" />
        </div>
        <br><br><br><br><br>
        <el-button size="small" @click="close">
          取消
        </el-button>
        <el-button type="primary" size="small" @click="submit">
          保存
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getRoleList, cancelFetch } from '@/api/role';
import { getDepartmentList } from "@/api/department";
import { createAccount, updateAccount } from '@/api/account';
import PermissionSettingApp from '@/components/permission/permission-setApp';

export default {
  name: 'UserDrawer',
  components: {
    PermissionSettingApp,
  },
  data () {
    const phoneRegexp = /^1[3-9]\d{9}$/;
    const emailRegexp = /^([a-zA-Z0-9])+@([a-zA-Z0-9])+\.([a-zA-Z0-9])+$/;
    const checkPhoneNumber = (rule, value, callback) => {
      callback(phoneRegexp.test(value) ? undefined : new Error('手机号码格式有误'));
    };
    const checkEmail = (rule, value, callback) => {
      if (this.formModel.isSendEmail === 0) callback();
      else callback(emailRegexp.test(value) ? undefined : new Error('邮箱格式有误'));
    };

    return {
      visible: false,
      defaultCheckedIds: [],
      formModel: {
        username: '',
        nickname: '',
        email: '',
        roleIds: [],
        departmentIds: [],
        isSendEmail: 0, // 1是发送 0是不发送
        status: 3,
      },
      oldEmail: null,
      formRules: {
        username: [{ required: true, message: '手机号不能为空' }, { validator: checkPhoneNumber }],
        nickname: [{ required: true, message: '账号姓名不能为空' }],
        roleIds: [{ required: true, message: '角色不能为空' }],
        departmentIds: [{ required: true, message: '部门不能为空' }],
        email: [{ validator: checkEmail }],
      },
      roleList: [],
      departmentList: [],
      account: null, // 当前编辑的账号
      userPermissionsIds: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      permissionList: state => state.role.permissionList,
    })
  },
  async mounted () {
    const res = await getRoleList({ pageSize: 10000 });
    this.roleList = res.list;
    this.getDepartmentList();
  },
  methods: {
    ...mapActions({
      getPermissionList: 'role/getPermissionList',
    }),
    async getDepartmentList () {
      const { list } = await getDepartmentList();
      this.departmentList = list;
    },
    /**
     * 编辑的时候设置当前编辑的账户信息
     * @param {Object} data
     * @param {Array} data.userHasRoleHasPermissions 色权限
     * @param {Array} data.userHasPermissions 用户权限
     * @param {Array} data.departments 部门
     * @param {Array} data.roles 角色
     */
    async setAccount (data) {
      // console.log('setAccount', data);
      this.visible = true;
      const rolePermissionsIds = data.userHasRoleHasPermissions.map(item => item.id); // 角色权限
      const userPermissionsIds = data.userHasPermissions.map(item => item.id); // 用户权限
      this.defaultCheckedIds = [...rolePermissionsIds, ...userPermissionsIds];
      this.userPermissionsIds = userPermissionsIds;
      this.loading = true;
      await this.getPermissionList();
      this.account = data;
      const roleIds = data.roles && data.roles.map(item => item.id) || [];
      const departmentIds = data.departments && data.departments.map(item => item.id) || [];
      const departmentList = this.openChildren(this.departmentList);
      departmentIds.forEach((item, index) => {
        for (const dep of departmentList) {
          if (dep.id === item) {
            departmentIds[index] = [...this.getParentId(dep.parentId, departmentList), item];
          }
        }
      });
      this.$nextTick(() => {
        this.formModel = {
          username: data.username,
          nickname: data.nickname,
          email: data.email,
          roleIds,
          departmentIds: departmentIds,
          isSendEmail: 0,
          status: Number(data.status)
        };
        this.oldEmail = data.email;
        // this.defaultCheckedIds = [...rolePermissionsIds, ...userPermissionsIds];
        // this.$refs.permissionSetting.setDisabled(rolePermissionsIds);
        this.loading = false;
      });
    },
    /**
     * 选择角色，角色已拥有的权限改为不可编辑
     */
    handlerSelectRole (values) {
      if (!this.account) return;
      // const permissionIds = this.roleList
      //   .filter(item => values.includes(item.id))
      //   .reduce((pre, cur) => pre.concat(cur.permissions), [])
      //   .map(item => item.id);
      // this.$refs.permissionSetting.setDisabled(permissionIds);
      // this.$refs.permissionSetting.setCheckedKeys([...permissionIds, ...this.userPermissionsIds]);
    },
    openChildren (list) {
      let arr = [];
      for (const item of list) {
        arr.push(item);
        if (item.children) {
          arr = [...arr, ...this.openChildren(item.children)];
        }
      }
      return arr;
    },
    getParentId (parentId, list) {
      let arr = [];
      if (parentId === 0) return arr;
      for (const item of list) {
        if (item.id === parentId) {
          arr.push(item.id);
          if (item.parentId !== 0) {
            arr = [...this.getParentId(item.parentId, list), ...arr];
          }
        }
      }
      return arr;
    },
    async open () {
      if (this.permissionList.length === 0) {
        this.loading = true;
        this.getPermissionList().finally(() => this.loading = false);
      }
      this.visible = true;
    },
    close () {
      this.reset();
      cancelFetch("cancel");
    },
    reset () {
      this.formModel.status = 3;
      this.formModel.isSendEmail = 0;
      this.account = null;
      this.visible = false;
      this.$refs.form.resetFields();
    },
    /**
     * 创建/编辑 账号
     */
    async submit () {
      try {
        const state = await this.$refs.form.validate();
        if (!state) return;
        const departmentIds = this.formModel.departmentIds.map(item => item[item.length - 1]);
        const params = Object.assign({}, this.formModel, { departmentIds });
        let res = {};
        if (!params.email || params.email === this.oldEmail) {
          delete params.email;
        }
        if (this.account) {
          params.id = this.account.id;
          // params.permissionIds = this.$refs.permissionSetting.getChecked();
          params.permissionIds = [];
          res = await updateAccount(params);
        } else {
          res = await createAccount(params);
        }
        let message = this.account ? '修改成功' : '创建成功';
        this.$message.success(message);
        this.reset();
        this.$emit('refresh');
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.user-operation {
  min-height: 100%;
  height: auto;
  background: #ffffff;
  box-sizing: border-box;
  padding: 20px;
  .rule-setting {
    > p {
      width: 80px;
      text-align: right;
      box-sizing: border-box;
      padding-right: 12px;
      font-size: 14px;

      :first-child {
        color: #666666;
      }

      :first-child::before {
        content: '*';
        color: #f56c6c;
        margin-right: 4px;
      }
    }
  }
}
</style>

<style lang="scss">
.user-operation-drawer {
  overflow: auto;
  .el-drawer__header {
    padding-top: 0;
    margin-bottom: 0;
    color: #222222;
    border-bottom: 1px solid #e9e9e9;
  }
}
</style>
