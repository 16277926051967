<template>
  <div class="permission-subTable">
    <div v-if="!menu.showRow" class="content-item">
      <div class="cell">
        <span v-if="menu.children && menu.children.length" class="table-expand" @click="handleExpand">
          <i v-if="menu.expand" class="el-icon-minus table-expand" />
          <i v-else class="el-icon-plus table-expand" />
        </span>
        <el-tooltip effect="dark" :content="menu.name " placement="right">
          <el-checkbox v-model="menu.checked" class="hasEllipsis" :indeterminate="menu.indeterminate" @change="(checked) => handleCheck(checked, menu)">
            {{ menu.name }}
          </el-checkbox>
        </el-tooltip>
      </div>
      <div v-show="menu.expand" class="last-content">
        <template v-if="menu.children && menu.children.length">
          <div v-for="child in menu.children" :key="child.id" class="last-child-item">
            <el-checkbox v-model="child.checked" @change="(checked) => handleCheck(checked, child)">
              {{ child.name }}
            </el-checkbox>
          </div>
        </template>
      </div>
      <div v-show="!menu.expand" style="flex:1;border: 0.5px solid #dcdfe6;" />
    </div>
    <div v-else class="table">
      <div class="title">
        <el-tooltip effect="dark" :content="menu.name " placement="right">
          <el-checkbox v-model="menu.checked" class="hasEllipsis" :indeterminate="menu.indeterminate" @change="(checked) => handleCheck(checked, menu)">
            {{ menu.name }}
          </el-checkbox>
        </el-tooltip>
      </div>
      <div class="content">
        <SubTable v-for="item in menu.children" :key="item.id" :menu="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubTable',
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
    };
  },
  watch: {
    'menu.children': {
      handler (data) {
        if (data) {
          if (!data.length) return;
          const checkedAll = !data.some(item => !item.checked);
          const menu = this.menu;
          this.$set(menu, 'checked', checkedAll);
          const isIndeterminate = data.filter(item => (item.indeterminate));
          if (isIndeterminate.length) {
            this.$set(menu, 'indeterminate', true);
          } else {
            const checkChild = data.filter(item => (item.checked));
            const indeterminate = checkChild.length < data.length && checkChild.length > 0;
            this.$set(menu, 'indeterminate', indeterminate);
          }

        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted () {
  },
  methods: {
    handleExpand () {
      this.$set(this.menu, 'expand', !this.menu.expand);
    },
    handleCheck (checked, item) {
      this.checkChild(checked, item);
    },
    checkChild (checked, data) {
      if (data.children && data.children.length) {
        data.children.forEach(item => {
          this.checkChild(checked, item);
        });
      } else {
        data.checked = checked;
      }
    },
  }
};
</script>

<style scoped lang="scss">
.table {
  display: flex;
  overflow: auto;
}
.title {
  width: 158px;
  display: flex;
  align-items: center;
  border: 0.5px solid #dcdfe6;
  padding: 0 4px;
}
.content {
  flex: 1;
}
.item-content {
  display: flex;
}
.cell {
  width: 158px;
  border: 0.5px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 4px;
  .table-expand {
    cursor: pointer;
    margin-right: 2px;
  }
}

.last-content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  border: 0.5px solid #dcdfe6;
  max-height: 200px;
  overflow: auto;
  padding: 0 12px;
}
.last-child-item {
  flex: 40%;
}
.content-item {
  display: flex;
}
</style>
<style lang="scss">
.permission-subTable {
  .hasEllipsis {
    .el-checkbox__label {
      width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
    }
  }
}
</style>
