<template>
  <div class="permission-setApp">
    <div class="title">
      <div>基础权限</div>
      <div>
        <el-checkbox
          v-model="checkAll"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
          全部权限
        </el-checkbox>
      </div>
    </div>
    <div class="content">
      <div
        v-for="(item, index) in list"
        :key="item.name + index"
        class="content-item"
        :class="index === list.length - 1 ? 'border-none' : ''"
      >
        <div class="label">
          {{ item.name }}
        </div>
        <div class="check-radio">
          <div>
            <el-radio-group v-model="item.rangeType" :disabled="item.disabled" @change="radioChange">
              <el-radio :label="0">
                无
              </el-radio>
              <el-radio :label="1">
                全部
              </el-radio>
              <el-radio :label="2">
                细分
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="item.rangeType === 2 " class="edit">
            <el-button type="text" @click="handleEdit(item)">
              编辑
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="编辑权限"
      :visible.sync="dialogVisible"
      append-to-body
      width="60%"
      class="permission-dialog"
    >
      <div style="border: 0.5px solid #dcdfe6;">
        <SubTable :menu="permissionItem" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SubTable from './subTable';
export default {
  name: 'PermissionSetApp',
  components: {
    SubTable
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      list: [],
      ids: [],
      isIndeterminate: false,
      checkAll: false,
      dialogVisible: false,
      permissionItem: {},
    };
  },
  watch: {
    menuList: {
      deep: true,
      handler () {
        this.list = JSON.parse(JSON.stringify(this.menuList));
        this.showRow(this.list);
        this.checkAll = !this.list.some(item => !(item.rangeType === 1));
        const checkApp = this.list.filter(item => item.rangeType === 1);
        this.isIndeterminate = checkApp.length < this.list.length && checkApp.length > 0;
      },
      immediate: true,
    },
  },
  mounted () { },
  methods: {
    // 给父组件
    getChecked () {
      const rangList = [];
      this.list.forEach(item => {
        const ids = [];
        this.getIds(item, ids);
        rangList.push({
          appId: item.id,
          permissionIds: ids,
          rangeType: item.rangeType
        });
      });
      return rangList;
    },
    // 获取所有的勾选 | 半选id
    getIds (item, ids) {
      if (item.children && item.children.length) {
        item.children.forEach(child => {
          this.getIds(child, ids);
        });
      }
      if (item.checked || item.indeterminate) ids.push(item.id);
    },
    // 设置app的勾选状态
    setAppCehckStatus (params) {
      this.list.forEach(item => {
        params.forEach(app => {
          if (item.id === app.appId) {
            this.$set(item, 'rangeType', app.rangeType);
          }
        });
      });
    },
    handleCheckAllChange (val) {
      const radio = val ? 1 : 0;
      // 半选存在 全选
      if (this.isIndeterminate) {
        this.list.forEach(item => {
          if (item.disabled) return;
          this.$set(item, 'rangeType', 1);
        });
      } else {
        this.list.forEach(item => {
          if (item.disabled) return;
          this.$set(item, 'rangeType', radio);
        });
      }
      this.isIndeterminate = false;
      this.pushIds();
    },
    radioChange () {
      this.pushIds();
      const ids = this.ids;
      this.checkboxStatus(ids);
    },
    pushIds () {
      let ids = [];
      this.list.forEach(item => {
        if (item.rangeType === 1) {
          ids.push(item.id);
        }
      });
      this.ids = ids;
    },
    checkboxStatus (ids) {
      const list = this.list;
      const checkAll = ids.length === list.length;
      const isIndeterminate = ids.length > 0 && ids.length < list.length;
      this.checkAll = checkAll;
      this.isIndeterminate = isIndeterminate;
    },
    handleEdit (item) {
      this.permissionItem = item;
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
    },
    handleSubmit () {
      this.dialogVisible = false;
    },
    // 加入showRow属性,用于递归组件
    showRow (list) {
      list.forEach(item => {
        const showRow = this.hasThreeChild(item.children);
        this.$set(item, 'showRow', showRow);
        if (item.children && item.children.length) {
          this.showRow(item.children);
        }
      });
    },
    hasThreeChild (list) {
      return list.some(item => item.children.length);
    },
  },
};
</script>
<style lang="scss">
.permission-dialog {
  .el-dialog {
    margin-top: 10vh !important;
  }
  .el-dialog__body {
    height: 60vh;
    overflow: auto;
  }
}
</style>
<style scoped lang="scss">
.permission-setApp {
  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-right: 24px;
  }
  .content {
    border: 1px solid #dcdfe6;
    padding: 4px;
    .content-item {
      display: flex;
      align-items: center;
      height: 36px;
      border-bottom: 1px solid #dcdfe6;
      padding: 0 6px;
      .label {
        flex: 1;
      }
      .check-radio {
        display: flex;
        width: 300px;
        align-items: center;
        .edit {
          margin-left: 12px;
        }
      }
    }
    .border-none {
      border: none;
    }
  }
}
</style>
