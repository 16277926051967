import fetch from './req';

export function updateAccountStatus (id, {status, canLoginBackend}) {
  return fetch.post(`/user/update`, { id, status, canLoginBackend });
}

export function createAccount (params) {
  return fetch.post(`/user/create`, params);
}

export function updateAccount (params) {
  return fetch.post(`/user/update`, params);
}

export function getResetPageUrl (id) {
  return fetch.post(`user/adminResetPassword`, {id});
}

export function sendEmail (path) {
  return fetch.post(`/user/adminResetPasswordSendEmail`, {path});
}

/**
 * @param params
 * @param {String} params.path
 * @param {String} params.password
 * @param {String} params.confirmPassword
 */
export function resetPassword (params) {
  return fetch.post(`/user/modifyPassword/${params.path}`, params);
}

export function deleteAccount (params) {
  return fetch.post(`/user/delete`, params);
}
