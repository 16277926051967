<template>
  <div class="user-page">
    <Organization
      title="部门组织架构"
      :data="departmentData"
      :default-props="defaultProps"
      :menus="departmentMenus"
      :active-id="activeId"
      @on-click-btn="handleOpenDialog"
      @on-click="handClickNode"
      @on-contextmenu="handleContentMenu"
    />
    <div class="finder-container">
      <SpFinder
        ref="finder"
        :url="{
          data: `${userList}?departmentId=${customParams.departmentId}`
        }"
        :columns="columns"
        :search="search"
        :actions="actions"
        title="账号管理"
      />
    </div>
    <UserDrawer ref="userDrawer" @refresh="() => $refs.finder.refresh()" />

    <el-dialog
      title="新增部门"
      :visible.sync="departmentDialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleDepartmentClose"
    >
      <el-form ref="departmentForm" :model="departmentForm" :rules="departmentRules" label-width="120px">
        <el-form-item v-if="curStatus!='rename'">
          <el-input v-model="searchVal" size="small" placeholder="搜索" prefix-icon="el-icon-search" clearable />
        </el-form-item>
        <el-form-item v-if="departmentData.length > 0 && curStatus!='rename'" label="选择部门：" required prop="parentId">
          <el-tree
            ref="dialog_department_tree"
            node-key="id"
            check-on-click-node
            highlight-current
            :data="departmentData"
            :props="defaultProps"
            :expand-on-click-node="false"
            :default-expanded-keys="dialogDefaultExpandedKeys"
            :filter-node-method="filterDialogNode"
            @node-click="handleDeparmentNodeClick"
          />
        </el-form-item>
        <el-form-item v-if="curStatus != 'move'" label="部门名称：" prop="name">
          <el-input v-model="departmentForm.name" size="small" placeholder="限20个字符" maxlength="20" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleDepartmentClose">取 消</el-button>
        <el-button size="small" type="primary" @click="handleDepartmentSubmit('departmentForm')">确 定</el-button>
      </span>
    </el-dialog>
    <UserResetPassword ref="userResetPassword" @edit-account="e => editAccount(e)" />
  </div>
</template>

<script>
import Organization from '@/components/organization';
import { updateAccountStatus, deleteAccount } from '@/api/account';
import UserDrawer from './component/user_drawer';
import { getDepartmentList, createDepartment, updateDepartment, deleteDepartment } from '@/api/department';
import { schemaSetting, userList } from '@/api/finder';
import UserResetPassword from '@/views/permission/account/component/user_reset_password';

export default {
  components: { UserResetPassword, Organization, UserDrawer },
  data () {
    const validateDepartmentName = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入部门名称'));
      }
      let text = '';
      text = this.validateDepartmentName(this.departmentData, value);
      if (text) {
        return callback(text);
      }
      callback();
    };
    const renderColSwitch = (row) => (<el-switch
      value={row.status}
      activeValue={'3'}
      inactiveValue={'1'}
      onChange={e => this.handleSwitchChange(e, row)} />);
    const loginBackendSwitch = (row) => (<el-switch
      value={row.canLoginBackend}
      activeValue={'1'}
      inactiveValue={'0'}
      onChange={e => this.isLoginBackstage(e, row)} />);
    return {
      id: '',
      schemaSetting,
      userList,
      visible: false,
      columns: [
        { name: '姓名', key: 'nickname' },
        { name: '手机号', key: 'username' },
        {
          name: '启用状态', key: 'status',
          render: (h, { row }) => renderColSwitch(row)
        },
        { name: '所属部门', key: 'deps', render: (h, { row }) => row.departments.map(item => item.name).join(',') },
        { name: '岗位权限', key: 'u2', render: (h, { row }) => row.roles.map(item => item.name).join(',') },
        {
          name: '是否允许登陆后台', key: 'canLoginBackend',
          render: (h, { row }) => loginBackendSwitch(row)
        },
        {
          "componentType": "0",
          "displayInForm": true,
          "field_type": "varchar",
          "filter": true,
          "index": 1,
          "key": "email",
          "name": "邮箱",
          "readOnly": false,
          "search": true,
          "show": true,
          "width": 120
        },
        {
          "componentType": "0",
          "displayInForm": true,
          "field_type": "datetime",
          "filter": false,
          "index": 3,
          "key": "lastLoginAt",
          "name": "最后登录时间",
          "readOnly": false,
          "search": false,
          "show": true,
          "width": 120
        }
      ],
      search: [
        {
          "key": "email",
          "name": "邮箱",
          "type": "text"
        },
        {
          "key": "nickname",
          "name": "姓名",
          "type": "text"
        },
        {
          "key": "status",
          "name": "启用状态",
          "options": [
            {
              "label": "启用",
              "value": "3"
            },
            {
              "label": "禁用",
              "value": "1"
            }
          ],
          "type": "select"
        },
        {
          "key": "username",
          "name": "手机号",
          "type": "text"
        }
      ],
      actions: [
        {
          name: '创建账号',
          key: 'create',
          slot: 'header',
          type: 'button',
          buttonType: 'primary',
          action: {
            type: null,
            handler: () => this.$refs.userDrawer.open()
          }
        },
        {
          name: '重置密码',
          key: 'edit',
          type: 'button',
          block: 'footer',
          action: {
            type: null,
            handler: (row) => this.resetPassword(row)
          }
        },
        {
          name: '编辑',
          key: 'edit',
          type: 'button',
          block: 'footer',
          action: {
            type: null,
            handler: (row) => this.editAccount(row)
          }
        },
        {
          name: '删除',
          key: 'delete',
          type: 'button',
          block: 'footer',
          action: {
            type: null,
            handler: (rows) => {
              this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                for (let i = 0; i < rows.length; i++) {
                  this.deleteAccount(rows[i].id, i);
                }
              });
            }
          }
        },
      ],
      departmentData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      pageNo: 1,
      pageSize: 10000,
      dialogVisible: false,
      departmentDialogVisible: false,
      departmentForm: {
        id: null,
        parentId: 0,
        name: ''
      },
      departmentRules: {
        name: [
          { validator: validateDepartmentName, trigger: 'blur' },
        ],
        parentId: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ]
      },
      curStatus: '',
      curDepartment: {},
      dialogDefaultExpandedKeys: [],
      searchVal: '',
      departmentMenus: [
        {
          type: 'add',
          name: '添加部门'
        },
        {
          type: 'rename',
          name: '重命名'
        },
        {
          type: 'move',
          name: '移动'
        },
        {
          type: 'delete',
          name: '删除部门'
        }
      ],
      activeId: null,
      customParams: {
        departmentId: '',
      }
    };
  },
  watch: {
    searchVal (val) {
      this.$refs.dialog_folder_tree.filter(val);
    }
  },
  mounted () {
    this.fetchDepartmentList();
  },
  methods: {
    editAccount (row) {
      this.$refs.userDrawer.setAccount(row[0]);
    },
    resetPassword (row) {
      const text = '<div style="text-align: center">密码重置成功后，员工登录时需重新设置新密码</div><div style="text-align: center">确定重置密码？</div>';
      this.$confirm(text, {
        dangerouslyUseHTMLString: true
      })
        .then(() => this.$refs.userResetPassword.open(row[0]));
    },
    fetchDepartmentList () {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      getDepartmentList(params).then(res => {
        const { list } = res;
        this.departmentData = list;
        if (!this.activeId && list.length > 0) {
          this.customParams.departmentId = list[0].id;
        }
        this.id = '45';
        this.handleContentMenu();
      });
    },
    validateDepartmentName (list, value) {
      let msg = '';
      list.map(item => {
        if (item.name === value) {
          msg = '部门已存在';
        }
        if (item.children && item.children.length > 0) {
          msg = this.validateDepartmentName(item.children, value);
        }
      });
      return msg;
    },
    handleContentMenu (id) {
      this.checkDepartmentMenu(this.departmentData, id);
      this.departmentData = JSON.parse(JSON.stringify(this.departmentData));
    },
    checkDepartmentMenu (list, id) {
      if (list && list.length > 0) {
        for (let item of list) {
          if (id === null || item.id !== id) {
            item.showMenu = false;
          } else {
            item.showMenu = true;
          }
          if (item.children && item.children.length > 0) {
            this.checkDepartmentMenu(item.children, id);
          }
        }
      }
    },
    handClickNode (data) {
      if (data.id !== this.activeId) {
        this.curDepartment = data;
        this.activeId = data.id;
        this.customParams.departmentId = String(data.id);
        this.handleContentMenu();
      }
    },
    handleOpenDialog (type, item) {
      this.curStatus = type;
      this.departmentDialogVisible = type !== 'delete';
      this.curDepartment = item || {};
      if (item && type !== 'delete') {
        setTimeout(() => {
          this.dialogDefaultExpandedKeys = [item.id];
          this.$refs.dialog_department_tree.setCurrentKey(item.id);
        }, 10);
      }
      if (type === 'delete') {
        this.departmentForm.id = item.id;
        this.departmentForm.parentId = item.parentId;
        this.removeDepartment();
      } else if (type === 'rename') {
        this.departmentForm.id = item.id;
        this.departmentForm.parentId = item.parentId;
        this.departmentForm.name = item.name;
      } else if (type === 'add') {
        this.departmentForm.id = null;
        this.departmentForm.parentId = item ? item.id : null;
        this.departmentForm.name = '';
      } else {
        this.departmentForm.name = item.name;
        this.departmentForm.id = item.id;
        this.departmentForm.parentId = item.parentId;
      }
      this.checkDepartmentMenu(this.departmentData, null);
    },
    handleDepartmentClose () {
      this.departmentDialogVisible = false;
      this.dialogDefaultExpandedKeys = [];
      this.$refs['departmentForm'].resetFields();
    },
    handleDepartmentSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.curStatus === 'add') {
            this._addDepartment(this.departmentForm);
          } else if (this.curStatus === 'rename' || this.curStatus === 'move') {
            this._updateDepartment(this.departmentForm);
          }
        } else {
          return false;
        }
      });
    },
    handleDeparmentNodeClick (data) {
      this.departmentForm.parentId = data.id;
    },
    filterDialogNode (value, data) {
      if (!value) {
        return true;
      }
      return data.name.indexOf(value) !== -1;
    },
    _addDepartment (params) {
      createDepartment(params).then(() => {
        this.$message.success('添加成功');
        this.fetchDepartmentList();
        this.handleDepartmentClose();
        this.$refs.userDrawer.getDepartmentList();
      });
    },
    _updateDepartment (params) {
      updateDepartment(params).then(() => {
        this.$message.success('修改成功');
        this.handleDepartmentClose();
        this.fetchDepartmentList();
        this.$refs.userDrawer.getDepartmentList();
      });
    },
    removeDepartment () {
      this.$confirm('确定删除该部门?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDepartment({ id: this.departmentForm.id }).then(() => {
          this.$message.success('删除成功');
          this.fetchDepartmentList();
          if (this.departmentForm.id === this.activeId) {
            this.activeId = '';
          }
        });
      });
    },
    async isLoginBackstage (status, row) {
      console.log(status);
      const newStatus = status === '1' ? '1' : '0';
      this.$set(row, 'canLoginBackend', newStatus);
      await updateAccountStatus(row.id, { canLoginBackend: newStatus });
      this.$refs.finder.refresh();
    },
    async handleSwitchChange (status, row) {
      const newStatus = status === '3' ? '3' : '1';
      this.$set(row, 'status', newStatus);
      await updateAccountStatus(row.id, { status: newStatus });
      this.$refs.finder.refresh();
    },
    async deleteAccount (id, index) {
      await deleteAccount({ id });
      if (index === 0) {
        this.$message.success('删除成功');
      }
      this.$refs.finder.refresh(true);
    }
  }
};
</script>
<style lang='scss' scoped>
.user-page {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  .finder-container {
    width: calc(100% - 264px - 24px);
    margin-left: 24px;
    min-height: 100%;
    background: #fff;
  }
  .el-switch {
    transform: scale(0.8);
  }
}
</style>
