<template>
  <div class="sp-organization">
    <div class="sp-organization__title">
      {{ title }}
    </div>
    <div class="sp-organization__main">
      <div class="sp-organization__search">
        <el-input v-model="searchVal" size="small" placeholder="请输入关键字" suffix-icon="el-icon-search" clearable />
        <el-button size="small" icon="el-icon-plus" @click="handleAdd('add')" />
      </div>
      <div class="sp-organization__tree">
        <el-tree
          ref="department_tree"
          node-key="id"
          check-on-click-node
          highlight-current
          :data="data"
          :props="defaultProps"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          :default-expanded-keys="defaultExpandedKeys"
          :draggable="draggable"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          @node-click="handleNodeClick"
          @node-contextmenu="handleShowMenus"
          @node-expand="handleExpand"
          @node-collapse="handleCollaspe"
          @node-drag-start="handleDragStart"
          @node-drag-enter="handleDragEnter"
          @node-drag-leave="handleDragLeave"
          @node-drag-over="handleDragOver"
          @node-drag-end="handleDragEnd"
          @node-drop="handleDrop"
        >
          <div slot-scope="scope" class="custom-tree-node">
            <div class="tree-node-name">
              <span>{{ scope.node.label }}</span>
            </div>
            <el-popover
              v-model="scope.data.showMenu"
              width="120"
              placement="right-start"
              :popper-options="{boundariesElement: 'body', gpuAcceleration: false}"
              @hide="showDepartmentMenu = false"
              @after-enter="showDepartmentMenu = true"
            >
              <template v-for="(item, index) in menus">
                <div v-if="item.type === 'add' || scope.data.parentId !== 0" :key="index" class="btn" @click="handleClickBtn(item.type, scope.data)">
                  {{ item.name }}
                </div>
              </template>
              <span slot="reference" />
            </el-popover>
          </div>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => '部门组织架构'
    },
    data: {
      type: Array,
      require: true
    },
    defaultProps: {
      type: Object
    },
    menus: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: String
    },
    draggable: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      searchVal: '',
      defaultExpandedKeys: []
    };
  },
  watch: {
    searchVal (val) {
      this.$refs.department_tree.filter(val);
    },
    data () {
      this.initData();
    }
  },
  mounted () {
    this.initData();
  },
  methods: {
    initData () {
      if (this.data.length === 0) {
        return;
      }
      let ids = this.data.map(item => item.id);
      this.defaultExpandedKeys = ids;
      setTimeout(() => {
        if (!this.activeId) {
          this.$refs.department_tree.setCurrentKey(this.data[0].id);
        } else {
          this.$refs.department_tree.setCurrentKey(this.activeId);
        }
      }, 10);
    },
    handleAdd (type) {
      console.log(type);
      this.$emit('on-click-btn', type);
    },
    handleNodeClick (data) {
      this.$emit('on-click', data);
    },
    handleShowMenus (e, data) {
      if (this.menus.length > 0) {
        this.$emit('on-contextmenu', data.id);
      }
    },
    handleClickBtn (type, data) {
      this.$emit('on-click-btn', type, data);
    },
    filterNode (value, data) {
      if (!value) {
        return true;
      }
      return data.name.indexOf(value) !== -1;
    },
    handleExpand (data) {
      this.defaultExpandedKeys.push(data.id);
    },
    handleCollaspe (data) {
      this.defaultExpandedKeys.splice(this.defaultExpandedKeys.indexOf(data.id), 1);
    },
    allowDrag () {
      return true;
    },
    allowDrop () {
      return true;
    },
    handleDragStart (node, ev) {
      console.log('drag start', node);
    },
    handleDragEnter (draggingNode, dropNode, ev) {
      console.log('tree drag enter: ', dropNode.label);
    },
    handleDragLeave (draggingNode, dropNode, ev) {
      console.log('tree drag leave: ', dropNode.label);
    },
    handleDragOver (draggingNode, dropNode, ev) {
      console.log('tree drag over: ', dropNode.label);
    },
    handleDragEnd (draggingNode, dropNode, dropType, ev) {
      console.log('tree drag end: ', dropNode && dropNode.label, dropType);
    },
    handleDrop (draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', dropNode.label, dropType);
    }
  }
};
</script>

<style lang='scss' scoped>
.sp-organization {
  width: 264px;
  background: #fff;
  border-radius: 2px;
  &__title {
    padding: 16px 24px;
    border-bottom: 1px solid #e9e9e9;
    color: #666;
  }
  &__main {
    padding: 14px 24px;
  }
  &__search {
    display: flex;
    justify-content: space-between;
    .el-input {
      width: 176px;
      &__inner {
        border-color: #d9d9d9;
      }
    }
    .el-button {
      width: 32px;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      border-color: #d9d9d9;
      color: #bbb;
    }
  }
  &__tree {
    margin-top: 22px;
  }
}
</style>
